import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import Loading from '../Loading'
import SearchHeader from '../SearchHeader'

export default class UserList extends Component {
  render() {
    const { loading, users, searchFunc, searchQuery, updateSearchQuery } = this.props
    return (
      <div className="col-lg-12 col-xl-8">
        <div className="row align-items-center">
          <div className="col-8">
            <h2>Users</h2>
          </div>
          <div className="col-4 text-right">
            <SearchHeader
              listFunc={searchFunc}
              searchQuery={searchQuery}
              updateSearchQuery={updateSearchQuery}
              title="Frames"
            />
          </div>
        </div>
        <hr />
        <div className="row align-items-center">
          <div className="col-12">
            {loading ? (
              <Loading />
            ) : (
              <div>
                {users.map((user) => (
                  <div key={user.id} className="row mt-3 align-items-center">
                    <div className="col-sm-6">
                      <h4 className="mb-0">
                        <Link to={`/admin-dash/users/${user.id}`}>{user.attributes.email}</Link>{' '}
                        {user.attributes.plus && <small className="plus-label">plus</small>}{' '}
                        {user.attributes.trialing && <small className="trial-label">trial</small>}
                      </h4>
                      {user.attributes.phone && <p className="mb-0">{user.attributes.phone}</p>}
                    </div>
                    <div className="col-sm-6 text-right">
                      <p>
                        <b>{user.attributes.num_frames}</b> frame
                        {user.attributes.num_frames !== 1 && 's'}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

UserList.propTypes = {
  loading: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  searchFunc: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  updateSearchQuery: PropTypes.func.isRequired,
}

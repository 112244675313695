import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_SHOW_REQUEST,
  USERS_SHOW_SUCCESS,
} from '../../actions/admin/users'

const DEFAULT_STATE = { gettingUsers: true, users: [], gettingUser: true, user: null, errors: [] }

export function users(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case USERS_REQUEST: {
      return { ...state, gettingUsers: true, users: [], errors: [] }
    }
    case USERS_SUCCESS: {
      return { ...state, gettingUsers: false, users: action.response.data }
    }
    case USERS_SHOW_REQUEST: {
      return { ...state, gettingUser: true, user: null, errors: [] }
    }
    case USERS_SHOW_SUCCESS: {
      return { ...state, gettingUser: false, user: action.response.data }
    }
    default:
      return state
  }
}

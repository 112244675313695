export const USERS_REQUEST = 'USERS_REQUEST'
export const USERS_SUCCESS = 'USERS_SUCCESS'
export const USERS_FAILURE = 'USERS_FAILURE'

export function list(q) {
  let endpoint = 'users'
  if (q) endpoint = `${endpoint}?q=${q}`
  return {
    type: USERS_REQUEST,
    api: {
      endpoint,
      isAdmin: true,
      authenticated: true,
      types: [USERS_REQUEST, USERS_SUCCESS, USERS_FAILURE],
      method: 'GET',
    },
  }
}

export const USERS_CREATE_REQUEST = 'USERS_CREATE_REQUEST'
export const USERS_CREATE_SUCCESS = 'USERS_CREATE_SUCCESS'
export const USERS_CREATE_FAILURE = 'USERS_CREATE_FAILURE'

export function create(data) {
  return {
    type: USERS_CREATE_REQUEST,
    api: {
      data,
      endpoint: 'users',
      isAdmin: true,
      authenticated: true,
      types: [USERS_CREATE_REQUEST, USERS_CREATE_SUCCESS, USERS_CREATE_FAILURE],
      method: 'POST',
    },
  }
}

export const USERS_SHOW_REQUEST = 'USERS_SHOW_REQUEST'
export const USERS_SHOW_SUCCESS = 'USERS_SHOW_SUCCESS'
export const USERS_SHOW_FAILURE = 'USERS_SHOW_FAILURE'

export function show(id) {
  const endpoint = `users/${id}`
  return {
    type: USERS_SHOW_REQUEST,
    api: {
      endpoint,
      isAdmin: true,
      authenticated: true,
      types: [USERS_SHOW_REQUEST, USERS_SHOW_SUCCESS, USERS_SHOW_FAILURE],
      method: 'GET',
    },
  }
}

export const USERS_APPROVE_BULK_SENDING_REQUEST = 'USERS_APPROVE_BULK_SENDING_REQUEST'
export const USERS_APPROVE_BULK_SENDING_SUCCESS = 'USERS_APPROVE_BULK_SENDING_SUCCESS'
export const USERS_APPROVE_BULK_SENDING_FAILURE = 'USERS_APPROVE_BULK_SENDING_FAILURE'

export function approveForBulkSending(id) {
  const endpoint = `users/${id}/approve_for_bulk_sending`
  return {
    type: USERS_APPROVE_BULK_SENDING_REQUEST,
    api: {
      endpoint,
      isAdmin: true,
      authenticated: true,
      types: [
        USERS_APPROVE_BULK_SENDING_REQUEST,
        USERS_APPROVE_BULK_SENDING_SUCCESS,
        USERS_APPROVE_BULK_SENDING_FAILURE,
      ],
      method: 'POST',
    },
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import User from '../../components/admin/User'

import NavContainer from '../NavContainer'

import {
  show,
} from '../../actions/admin/users'
import { list } from '../../actions/admin/userFrames'

class UserContainer extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { match, dispatch } = this.props
    const { id } = match.params
    dispatch(show(id))
    dispatch(list(id))
  }

  render() {
    const { history, loggedIn, isAdmin, user, errors, gettingUser } = this.props
    return (
      <div>
        <NavContainer history={history} />
        {loggedIn && isAdmin && (
          <div className="row justify-content-md-center">
            <User
              loading={gettingUser}
              user={user}
              errors={errors}
              backFunc={history.goBack}
            />
          </div>
        )}
      </div>
    )
  }
}

UserContainer.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  loggedIn: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  gettingUser: PropTypes.bool.isRequired,
  user: PropTypes.object,
  errors: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
  const { loggedIn, isAdmin } = state.session
  const { gettingUser, user, errors } = state.users
  return {
    loggedIn,
    isAdmin,
    gettingUser,
    user,
    errors,
  }
}

export default connect(mapStateToProps)(UserContainer)

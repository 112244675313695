import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AdminFramesContainer from '../../containers/admin/AdminFramesContainer'

import { DateConverter } from '../../utilities/DateConverter'

import Loading from '../Loading'
import Errors from '../Errors'

export default class User extends Component {
  render() {
    const {
      dispatch,
      loading,
      user,
      backFunc,
      errors,
    } = this.props
    const {
      email,
      created_at,
      reminder_profile_url,
      subscribed_to_emails,
    } = user?.attributes || {}
    return (
      <div className="col-lg-12 col-xl-8">
        <Errors errors={errors} />
        <div className="row align-items-center">
          <div className="col-1">
            <p className="mb-0">
              <button type="button" onClick={backFunc} className="btn btn-sm btn-info">
                ←
              </button>
            </p>
          </div>
          {!loading && (
            <div className="col-7">
              <h3 className="mb-0">{email}</h3>
              <a href={`/admin2/resources/users/${user.id}`} target="_blank">View in 🧠 Admin | Manage Plus</a>
              <p className="text-muted mb-0">
                Signed up at <b>{DateConverter.call(created_at)}</b>
              </p>
              {reminder_profile_url && (
                <p className="text-muted">
                  Marketing Emails: <b>{subscribed_to_emails ? 'Subscribed' : 'Unsubscribed'}</b>{' '}
                  <a href={reminder_profile_url}>
                    <span className="oi oi-gear" title="Edit" aria-hidden="true" /> Edit
                  </a>
                </p>
              )}
              <Link to={`/reset-password/${email}`}>Send password reset email</Link>
            </div>
          )}
        </div>
        <hr />
        <div className="row align-items-center">
          <div className="col-12">
            {loading ? <Loading /> : <AdminFramesContainer dispatch={dispatch} />}
          </div>
        </div>
      </div>
    )
  }
}

User.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object,
  backFunc: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
}
